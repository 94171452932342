@import './../../../../../vars';

@mixin _minus($rotate) {
  content: '';
  display: block;
  background: #000000;
  border-radius: 2px;
  width: 2px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ($rotate);
  transform-origin: center;

}

@mixin _orderTitle() {
  font-family: 'Roboto Slab', serif;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.order-duration {
  margin-bottom: 24px;

  &__title {
    @include _orderTitle();
    margin-bottom: 10px;
  }

  .select-box {
    position: relative;
    z-index: 3;
    cursor: pointer;

    &.is-opened {
      .select-box {
        &__value {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-color: $primary-color;
          border-left-color: $primary-color;
          border-right-color: $primary-color;
        }

        &__values {
          display: block;
        }
      }
    }

    &__current {
      position: relative;
    }

    &__value {
      border: 1px solid #DBDBDB;
      border-radius: 5px;
      padding: 15px 45px 15px 15px;
      color: #000;
    }

    &__arrow {
      position: absolute;
      width: 14px;
      height: 14px;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      color: #ACA6A6;
    }

    &__values {
      margin: 0;
      padding: 0;
      background-color: white;
      border: 1px solid $primary-color;
      border-top-color: #DBDBDB;
      position: absolute;
      bottom: 1px;
      transform: translateY(100%);
      width: 100%;
      display: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      li {
        list-style-type: none;
        padding: 15px 45px 15px 15px;
        color: black;

        &:hover {
          background-color: fade_out($primary-color,0.75);
        }

        &:not(:last-child) {
          border-bottom: 1px solid #DBDBDB;
        }
      }
    }
  }

}
