@import "./../../../../vars";

.order-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 15px;

    & .--phone {
        grid-column: 1/3;
    }



    .form-control {
        display: grid;
        grid-template-areas: "label" "input";

        &.--phone {
            margin-bottom: 25px;
        }

        input {
            grid-area: input;
            width: 100%;
            border-radius: 5px;
            border: 1px solid #DBDBDB;
            font-size: 17px;
            line-height: 20px;
            padding: 10px 15px;

            &:focus {
                outline: none;
                box-shadow: inset 0px 0px 0px 1px $primary-color;
                border-color: $primary-color;

                & + label {
                    color: $primary-color;
                }
            }
        }

        label {
            grid-area: label;
            font-size: 15px;
            line-height: 20px;
            font-family: Roboto Slab, serif;
            margin-bottom: 7px;
        }
    }
}
