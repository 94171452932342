@import "./../../../../vars";

.is-booked {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;

    &__container {
        background-color: white;
        border-radius: 5px;
        width: 305px;
        padding: 20px;
    }

    &__icon {
        width: 60px;
        height: 60px;
        margin: 20px auto ;
    }

    &__text {
        font-family: Roboto Slab, serif;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 40px;
    }
}
