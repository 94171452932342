@import "./../../../../vars";

.complete-order {
    padding: 20px 15px;

    &__header {
        margin-bottom: 15px;
        padding-bottom: 40px;
        border-bottom: 1px solid #DBDBDB;
    }

    &__icon {
        text-align: center;
        margin-bottom: 10px;
    }

    &__text-1 {
        font-family: Roboto Slab, serif;
        font-size: 25px;
        line-height: 33px;
        text-align: center;
        margin-bottom: 10px;
    }

    &__text-2 {
        text-align: center;
    }

    &__body {
        .company-info {
            display: block;
            padding-top: 20px;
            margin-bottom: 20px;

            &__name {
                text-transform: uppercase;
                margin-bottom: 5px;
            }

            &__address {
            }
        }

        .order {
            padding: 0;

            &__item {
                display: grid;
                grid-template-columns: 85px 1fr;
                font-size: 15px;
                line-height: 18px;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 6px;
                }

                .value {
                    color: #6A6A6A;
                }

                .price {
                    font-weight: 500;
                    font-size: 25px;
                    line-height: 29px;
                }
            }

            &__deposit-info {
                padding-top: 8px;
                font-size: 15px;
                line-height: 18px;
                color: #6A6A6A;
            }
        }
    }
}
