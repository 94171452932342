@import "./../../../../../vars";
@import "./../../../order";

.order-guest-counter {
  margin-bottom: 29px;

  &.is-disabled {
    opacity: 0.5;

    .order-guest-counter {
      &__btn {
        cursor: auto;

        &:hover {
          background-color: #F0F0F0;

          &:before, &:after {
            background-color: black !important;
          }
        }

      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__value {
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 50px);
    grid-gap: 10px;
  }

  &__alert {
    text-align: center;
  }

  &__btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F0F0F0;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: $primary-color;

      &:before, &:after {
        background-color: white !important;
      }
    }

    &:before {
      @include _minus(90deg);
    }

    &.inc {
      &:after {
        @include _minus(0);
      }
    }
  }
}
