@import "./../../../../vars";

.end-of-time {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  &__image {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }

  &__text {
    font-family: Roboto Slab, serif;
    font-size: 20px;
    line-height: 26px;
  }
}
