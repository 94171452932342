@import "./../../vars";

.view {
    position: relative;
    padding: 0 15px;

    &__header{
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        text-align: center;
        justify-content: center;
        margin-bottom: 15px;

        .icon {
            position: absolute;
            left: 15px;
            top: 15px;
            width: 20px;
            height: 20px;
        }

        .title {
            font-family: Roboto Slab, serif;
            font-size: 20px;
            line-height: 26px;
        }
    }


    &__body{
        padding-bottom: 60px;
    }
    &__footer{
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }
}


@mixin _orderTitle() {
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}

@mixin _minus($rotate) {
    content: '';
    display: block;
    background: #000000;
    border-radius: 2px;
    width: 2px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ($rotate);
    transform-origin: center;

}

.order {
    padding: 25px 15px;

    &__title {
        font-family: "Roboto Slab", serif;
        font-size: 25px;
        line-height: 33px;
        margin-bottom: 20px;
    }

    &__body {
        padding-bottom: 60px;
    }

    &__save-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        z-index: 10;
    }

    &__save {
        border-radius: 5px;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #FFFFFF;
        background-color: $primary-color;
        padding: 15px;
        width: 100%;
        text-align: center;

        &.is-disabled {
            background-color: #F0F0F0;
        }
    }
}

.order-date {

    margin-bottom: 24px;

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;
    }

    &__title {
        @include _orderTitle();
    }

    &__current-date {
        color: #ACA6A6;
        margin-top: 5px;
    }

    &__body {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(7, 1fr);

        li {
            list-style-type: none;

            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;

            span {
                color: #000;
                position: relative;
                z-index: 2;
            }

            &.is-active {

                span {
                    color: white;
                }

                &:before {
                    content: '';
                    display: block;
                    width: 51px;
                    height: 51px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-color: black;
                    border-radius: 50%;
                }
            }

            &.is-special-event {
                span {
                    color: #ACA6A6;
                    text-decoration: line-through;
                }
            }

            &:not(.is-active) {
                cursor: pointer;
            }

        }
    }

    &__week-day-names{
        display: flex;
        margin: 0 0 13px 0;
        padding: 0;

        li {
            list-style-type: none;
            text-align: center;
            color: #ACA6A6;
            font-size: 13px;
            line-height: 15px;
            flex-grow: 1;
        }
    }
}

.order-time {
    margin-bottom: 10px;

    &__title {
        @include _orderTitle();
    }

    ul {
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    li {
        list-style-type: none;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;

        &.is-disabled {

            &:not(.is-active) {
                text-decoration: line-through;
            }

            &.is-active:after {
                background-color: #ACA6A6;
            }

            color: #ACA6A6;
        }

        &.is-active {
            position: relative;
            color: white;

            &:after {
                content: '';
                display: block;
                width: 54px;
                height: 54px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: black;
                border-radius: 50%;
                position: absolute;
            }
        }
    }

    span {
        padding: 0.75em;
        position: relative;
        z-index: 1;
        cursor: pointer;
    }
}



.order-table-select {
    margin-bottom: 24px;

    &.is-disabled {
        opacity: 0.2;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__title {
        @include _orderTitle();
    }

    &__available {
        margin-top: 5px;
        color: #ACA6A6;
    }

    &__body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 11px;
        align-items: center;

        &.has-table {
            grid-template-columns: 1fr auto auto;
            grid-gap: 10px;
        }

        &:hover {
            .order-table-select__button {
                background-color: $primary-color;

                &:before, &:after {
                    background-color: white;
                }

                &-text {
                    color: $primary-color;
                }
            }
        }
    }

    &__btn {
        border-radius: 5px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        padding: 11px 15px;
        width: 100%;
        text-align: center;
        background-color: #F0F0F0;
        cursor: pointer;
    }

    &__delete {
        background: #F0F0F0;
        border-radius: 5px;
        width: 50px;
        height: 50px;
        padding: 15px;
    }

    &__edit {
        background: #F0F0F0;
        border-radius: 5px;
        height: 50px;
        padding: 16px 15px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
    }
}

.order-custom-table {
    margin: 0 15px 10px;
    background: #F0F0F0;
    border-radius: 5px;
    font-size: 15px;
    line-height: 18px;
    padding: 15px;
    text-align: center;
}

.order-comment {

    &__title {
        @include _orderTitle();
        margin-bottom: 10px;
    }

    textarea {
        padding: 15px 45px 15px 15px;
        border: 1px solid #DBDBDB;
        border-radius: 5px;
        color: #000000;
        width: 100%;
        height: 90px;
        resize: none;

        &:focus {
            outline: none;
            border-color: $primary-color;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}



