@import "./../../../../vars";

.view-map {

    &__title {
        position: fixed;
        top: 0;
        left: 0;
        min-width: 100%;
        height: 50px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        line-height: 26px;
        font-family: 'Roboto Slab', serif;
        z-index: 2;
    }

    &__body {
        padding-top: 50px;
        padding-bottom: 60px;
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__bottom {
        background-color: white;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(2, 1fr);
        z-index: 2;
    }

    @mixin __button() {
        text-align: center;

        border-radius: 5px;
        margin: 5px auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;

        &:not(.is-disabled) {
            cursor: pointer;
        }


    }

    &__apply {
        @include __button();
        color: white;
        background-color: #1EC025;

        &.is-disabled {
            background-color: #F0F0F0;
            color: white;
        }
    }

    &__cancel {
        @include __button();
        background-color: #F0F0F0;
    }



    &__table-number {
        font-size: 20px;
        line-height: 26px;
        font-family: Roboto Slab, serif;
    }

    &__info-text {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;

        span {
            font-weight: normal;
            opacity: 0.6;
        }
    ;
    }

    &__max-guests {
        margin-bottom: 4px;
    }
}

.view-map-info {
    position: fixed;
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 2;
    background: white;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    font-size: 15px;
    line-height: 18px;

    &__left {
        display: flex;
        align-items: center;


        >*:not(:last-child){
            margin-right: 5px;
        }
    }

    &__max-guests {
        color: #6A6A6A;
    }

    &__deposit {
        font-weight: bold;
        span {
            font-weight: normal;
            color: #6A6A6A;
        }
    }




    &.is-locked {
        background-color: fade_out(black, 0.3);
        justify-content: center;
    }

}

//#restaurantMap {
//    .table {
//        stroke: black;
//        fill: white;
//        cursor: pointer;
//
//        text {
//            stroke: none;
//            fill: black;
//        }
//
//        //&.is-disabled {
//        //    > rect {
//        //        fill: #5D5D5D;
//        //    }
//        //
//        //    .is-lock {
//        //        color: white;
//        //        stroke: none;
//        //    }
//        //}
//
//        &.is-active {
//            fill: $primary-color;
//
//            text {
//                fill: white;
//                stroke: none;
//            }
//
//            .shadow {
//                stroke: rgba(30, 192, 37, 0.6);
//                stroke-width: 8px;
//            }
//        }
//
//    }
//}
