@import "./../../../../vars";

.order-confirmation {
    .company-info {
        display: block;
        padding-top: 20px;
        margin-bottom: 20px;

        &__name {
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        &__address {
        }
    }

    .order {
        &__item {
            display: grid;
            grid-template-columns: 85px 1fr;
            font-size: 15px;
            line-height: 18px;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 6px;
            }

            .value {
                color: #6A6A6A;
            }

            .price {
                font-weight: 500;
                font-size: 25px;
                line-height: 29px;
            }
        }

        &__deposit-info {
            padding-top: 8px;
            font-size: 15px;
            line-height: 18px;
            color: #6A6A6A;
        }
    }

    .event-info {
        margin-top: 10px;
        background: #F0F0F0;
        border-radius: 5px;
        display: grid;
        grid-template-columns: 18px 1fr;
        grid-gap: 17px;
        align-items: center;
        padding: 11px 15px ;

        &__icon {
            color: #ACA6A6;
        }

        &__text {
            font-size: 15px;
            line-height: 18px;
            color: #6A6A6A;
        }
    }
}


