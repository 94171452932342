@import "./../../../../vars";

.order-empty-info {
    padding-top: 40px;

    &__icon {
        width: 60px;
        height: 60px;
        margin: 0 auto 20px;
    }

    &__text {
        font-family: Roboto Slab,serif;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
    }
}
