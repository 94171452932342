@import "./../../../../vars";

.notify {

  text-align: center;

  &__text {
    text-align: center;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &__code-field {
    text-align: center;

    input {
      border: 1px solid #DBDBDB;
      box-sizing: border-box;
      border-radius: 5px;
      text-align: center;
      height: 40px;
      outline: none;

      &:focus {
        border: 2px solid #1EC025;

      }
    }
  }

  &__time-alert {
    margin-top: 10px;
    text-align: center;
    color: red;
  }
}
