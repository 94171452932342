
.company-info {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto 1fr;
    margin-bottom: 24px;


    &__icon {
        width: 80px;
        height: 80px;
        background-color: #6A6A6A;
        border-radius: 5px;
        overflow: hidden;
        background-size: cover;
        background-position: center;

        &.is-bg-transparent {
            background-color: transparent;
        }
    }

    &__text {
        padding-top: 5px;
    }

    &__title {
        text-transform: uppercase;
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 7px;
    }

    &__address {
        font-size: 15px;
        line-height: 18px;
        color: #6A6A6A;
    }
}
