@import "~normalize.css";

//@import "~roboto-fontface";
//@import "fonts/roboto-slab/font";
@import "vars";

body {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 20px;

  * {
    box-sizing: border-box;
  }
}

html,
body {
  position: fixed;
  overflow: hidden;
}

body .wrapper {
  //width: 100vw;
  //height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
  padding-bottom: 60px;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 1200px) {
    max-width: 1140px;
  }
}

.btn {
  font-size: 17px;
  line-height: 20px;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  width: 100%;

  &--primary {
    background-color: $primary-color;
    color: white;
  }

  &.is-disabled {
    background-color: #F0F0F0;
  }
}

$margins : 5,10,15,20;
@each $margin in $margins {
  .mb-#{$margin} {
    margin-bottom: #{$margin}px !important;
  }
}

