@import "./../../../../vars";

.free-tables-mode-info {
    background: #F0F0F0;
    border-radius: 5px;
    padding: 20px 15px;

    &__title {
        font-family: Roboto Slab, serif;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 10px;
    }

    &__text {
        color: #6a6a6a;
        font-size: 15px;
        line-height: 18px;
        text-align: center;

        span {
            color: black;
        }
    }
}
