$primary-color: #1EC025;
$secondary-color: #fe4800;

$black: #333;

$grey-light:#ddd;
$grey: #888;

$base-border-radius: 4px;

$base-box-shadow: 0 0 10px 0 rgba(140, 140, 140, 0.25);
$hover-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

$base-padding: 25px 15px;

$bg-grey: #f6f7fb;


/** from figma */

$black-primary: #000000;
